<!-- 会员管理 -->

<template>
	<div class="order-prestore main-cnt">
		<div class="content">
			<div class="common-table">
				<div class="select-wp">
					<div class="select-left">
						<div class="select-item">
							<el-select v-model="condition.has_eq" clearable placeholder="是否有充电桩"
								@change="getMemberList">
								<el-option label="是" value="1"></el-option>
								<el-option label="否" value="2"></el-option>
							</el-select>
						</div>
						<div class="select-item">
							<el-select v-model="condition.is_member" placeholder="是否会员" clearable @change="getMemberList">
								<el-option label="是" value="1"></el-option>
								<el-option label="否" value="2"></el-option>
							</el-select>
						</div>
						<div class="select-item">
							<el-select v-model="condition.order_type" placeholder="是否有充电桩" @change="getMemberList">
								<el-option label="充电" value="1"></el-option>
								<el-option label="充值" value="2"></el-option>
								<el-option label="续期" value="3"></el-option>
							</el-select>
						</div>
						<div class="select-item">
							<el-input v-model="condition.keywords" placeholder="请输入用户手机号查询" clearable
								@input="getMemberList"></el-input>
						</div>
					</div>
				</div>
			</div>

			<div class="table-wp">
				<el-table :data="listTable" v-loading="isLoading" style="width: 100%" height="calc(100vh - 325px)"
					element-loading-text="数据加载中" @sort-change="changeTableSort"
					:default-sort="{ prop: 'm_ctime', order: 'descending' }">
					<el-table-column prop="m_id" label="ID" show-overflow-tooltip />
					<el-table-column prop="m_username" label="用户昵称" show-overflow-tooltip />
					<el-table-column prop="user_status" label="用户状态" show-overflow-tooltip />
					<el-table-column prop="m_mobile" label="手机号码" show-overflow-tooltip />
					<el-table-column prop="is_member_text" label="是否会员" show-overflow-tooltip />
					<el-table-column prop="count" label="订单总数" sortable width="110" show-overflow-tooltip />
					<el-table-column prop="money" label="消费合计" sortable width="120" show-overflow-tooltip />
					<el-table-column prop="mw_surplus_integral" label="可用积分" sortable width="120" show-overflow-tooltip />
					<el-table-column prop="car_no" label="车牌" show-overflow-tooltip />
					<el-table-column prop="m_ctime" label="注册时间" sortable width="160" show-overflow-tooltip />
					<el-table-column prop="m_login_time" label="最近一次登录" width="150" sortable show-overflow-tooltip />
					<el-table-column prop="is_private" label="是否有充电桩" show-overflow-tooltip>
						<template #default="scope">
							<template v-if="authData.indexOf('n_sjqG2TmAMcdbKTwvqRVLO7XAgJIP') != -1">
								<span @click="hasPrivate(scope.row)"
									:class="scope.row.is_private == '有'?'title-num-green text-link':''">
									{{scope.row.is_private}}
								</span>
							</template>
						</template>
					</el-table-column>

					<el-table-column fixed="right" label="操作" width="190">
						<template #default="scope">
							<template v-if="authData.indexOf('n_sjqG2TmAMcdbKTwvqRVLO7XAgJIP') != -1">
								<el-button type="primary" @click="info(scope.row)">订单详情</el-button>
							</template>
						</template>
					</el-table-column>
				</el-table>

				<div class="flex">
					<div class="paging">
						共<span>{{ total }}</span>条
					</div>
					<el-pagination v-model="condition.page" :page-sizes="[20, 50, 100, 200, 500]"
						:page-size="condition.limit" background layout="sizes, prev, pager, next, jumper" :total="total"
						@size-change="handleSizeChange" @current-change="handleCurrentChange">
					</el-pagination>
				</div>
			</div>
		</div>

		<!-- 是否有私桩详情 -->
		<w-dialog ref="detailsDialog" class="add-dialog" title="私桩详情" width="50%" btnWidth="140px" top="20vh"
			:hideFooter="true">
			<el-table :data="tableData" style="width: 100%" height="500">
				<el-table-column prop="e_no" label="充电桩编号" show-overflow-tooltip />
				<el-table-column prop="e_name" label="充电桩名称" show-overflow-tooltip />
				<el-table-column prop="ep_mobile" label="绑定用户" show-overflow-tooltip />
				<el-table-column prop="ep_metre_type" label="电表类型" show-overflow-tooltip />
				<el-table-column prop="or_status_text" label="有效期" show-overflow-tooltip>
					<template #default="scope">
						<div class="title-num-green">{{scope.row.ep_stime}}-{{scope.row.ep_etime}}</div>
					</template>
				</el-table-column>

				<el-table-column prop="or_status_text" label="操作" show-overflow-tooltip>
					<template #default="scope">
						<div class="title-num-green" v-if="authData.indexOf('n_wJjPomEOv4ysMsUKX3PxKfAX6cih') != -1"
							@click="unBind(scope.row)">解绑</div>
					</template>
				</el-table-column>
			</el-table>

		</w-dialog>

		<!-- 订单详情-->
		<commonChargingOrder ref="refCommonChargingOrder"></commonChargingOrder>
	</div>
</template>

<script setup>
	import {
		ref,
		onMounted,
		computed,
		watch,
	} from "vue";
	import {
		Member
	} from "@/plugins/api.js";
	import {
		ElMessage,
		ElMessageBox
	} from "element-plus";
	import {
		useStore
	} from "vuex";
	import commonChargingOrder from "../components/commonChargingOrder.vue";

	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const authData = ref([]);
	// 监听权限
	watch(() => menuTokens.value, (data) => {
		if (data.length) {
			authData.value = data;
		}
	}, {
		deep: true,
		immediate: true,
	})
	const listTable = ref([]);  // 列表表格数据
	const isLoading = ref(false);  /** 是否正在获取表格数据 */
	const detailsDialog = ref(null); // 详情对象
	const tableData = ref([]);  // 详情表格数据
	const condition = ref({  // 参数
		has_eq: '',
		keywords: '',
		sort_type: '1',  // 排序方式 默认 1-倒序；2-正序
		sort_column: 'm_ctime',  // 排序字段 m_ctime：注册时间 m_login_time ：最近一次登录 count ：订单数量 money： 消费金额  mw_surplus_integral:可用积分数
		order_type: '1',  // 订单类型 1-充电（预付和余额充电） 2-充值（余额充值） 3-续期（平台续期）
		is_member: '',  // 是否为会员1：是2：不是
		limit: 20,
		page: 1,
	});
	const total = ref(0);  // 分页总条数
	/**
	 * 
	 * 每页数量改变处理
	 * 
	 * **/
	const handleSizeChange = (number) => {
		condition.value.limit = number;
		getMemberList();
	};
	/**
	 * 
	 * 页码改变处理
	 * 
	 * **/
	const handleCurrentChange = (number) => {
		condition.value.page = number;
		getMemberList();
	};

	const unBind = (row) => {
		ElMessageBox.confirm(
			'是否确认解绑?',
			'温馨提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消',
			type: 'warning',
		}
		)
			.then(() => {
				Member.memberUnbind({
					ep_id: row.ep_id,
				}).then(res => {
					if (res.Code == 200) {
						detailsDialog.value.close()
						getMemberList();
					} else {
						ElMessage({
							type: 'warn',
							message: res.Message,
						})
					}
				})
			})
	}
	const refCommonChargingOrder = ref(null)
	const info = (item) => {
		refCommonChargingOrder.value.show(item.m_id)
	}
	const hasPrivate = (row) => {
		tableData.value = row.equipment_private_list;
		if (tableData.value.length == 0) return
		detailsDialog.value.show()
	}
	/**
	 * 
	 * 获取会员列表数据
	 * 
	 * **/
	const getMemberList = () => {
		isLoading.value = true;
		Member.getMemberList(condition.value).then(res => {
			isLoading.value = false;
			if (res.Code == 200) {
				total.value = res.Data.count;
				listTable.value = res.Data.list;
			} else {
				ElMessage.error(res.Message);
			}
		})
	}
	/**
	 * 
	 * 排序变化
	 * 
	 * **/
	const changeTableSort = (item) => {
		condition.value.sort_column = item.prop;
		condition.value.sort_type = item.order == 'descending' ? '1' : '2';
		getMemberList();
	}

	onMounted(() => {
		getMemberList();
	});
</script>

<style lang="scss">
	.order-prestore {
		font-family: "Source Han Sans CN";

		.text-link {
			cursor: pointer;

			&:hover {
				font-weight: bold;
				text-decoration: underline;
			}
		}

		.title-num-green {
			color: var(--theme-color);
		}

		.el-table__footer-wrapper tbody td.el-table__cell {
			background-color: #fff;
			border-color: #fff;
		}

		.el-table__fixed::before,
		.el-table::before,
		.el-table__fixed-right::before {
			background-color: #fff;
		}

		.el-row {
			border: none;
		}

		.content {
			padding: 20px;
		}

		.add-dialog {
			.el-dialog__body {
				padding: 20px 20px 30px 30px;

				.el-table__row {
					.el-table__cell {
						padding: 9px 0 !important;
					}
				}

			}
		}

		.table-wp {
			.el-table {
				border-radius: 4px 4px 0 0;

				thead {
					/* color: var(--text-color); */
				}

				th.el-table__cell {
					background-color: var(--search-bg-color);
					padding: 9px 0;
					font-size: 15px;
				}

				td.el-table__cell {
					padding: 9px 0;

					.cell {
						>.el-button {
							padding: 4px 0;
							min-height: 20px;
							width: 76px;
							background-color: transparent;
							border-radius: 20px;
							border: none;
							color: var(--theme-color);
							font-size: 14px;
							border: 1px solid var(--theme-color);

							&:hover {
								background-color: var(--theme-color);
								color: #fff;
							}
						}

						>.img-wp {
							display: inline-block;
							width: 32px !important;
							height: 32px !important;
							border-radius: 2px;
							margin-right: 8px;
							vertical-align: middle;
							position: relative;
							overflow: hidden;
							cursor: pointer;

							.el-image {
								width: 100% !important;
								height: 100% !important;
							}

							.remark {
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								background-color: rgba(0, 0, 0, 0.3);
								pointer-events: none;

								.dot {
									padding-left: 5px;
									line-height: 16px;

									span {
										display: inline-block;
										width: 4px;
										height: 4px;
										border-radius: 2px;
										background-color: #fff;
										margin-right: 4px;
									}
								}

								.num {
									font-size: 10px;
									color: #fff;
									text-align: center;
									line-height: 10px;
									vertical-align: middle;
								}
							}
						}

						>.block {
							width: 32px;
							height: 32px;
							background-color: var(--theme-color);
							border-radius: 3px;
							line-height: 32px;
							text-align: center;
							color: #fff;
							cursor: pointer;
						}

						>.no-auth-block {
							opacity: 0.6;
							cursor: not-allowed;
						}

						>.preview {
							width: 30px;
							height: 30px;
							line-height: 30px;
							border-radius: 3px;
							background-color: #e8e8e8;
							color: var(--text-gray-color);

							.iconfont {
								font-size: 12px;
								margin-left: 6px;
							}
						}

						>.preview.active {
							background-color: var(--search-bg-color);
							color: var(--theme-color);
							cursor: pointer;
						}

						>.qrcode {
							width: 30px;
							height: 30px;
							line-height: 30px;
							border-radius: 3px;
							background-color: var(--search-bg-color);
							color: var(--theme-color);
							cursor: pointer;
							text-align: center;

							.iconfont {
								font-size: 16px;
							}
						}
					}
				}

				td.el-table__cell.image {
					padding: 5px 0;
				}
			}

			.el-pagination {
				padding: 0;
				margin-top: 15px;
			}

			.el-pagination.is-background .el-pager li:not(.disabled).active {
				background-color: var(--theme-color);
			}
		}
	}


	.amount-title {
		font-size: 18px;
	}

	.order-total {
		margin-left: 100px;
		color: #1AC994;
	}
</style>